import UIkit from 'uikit';

function getDataParams(element, attributeName = 'data-vy-ajax') {
  const rawData = $(element).attr(attributeName);
  if (!rawData) return {};

  return rawData.includes('{') ? parseComplexFormat(rawData) : parseSimpleFormat(rawData);
}

function parseSimpleFormat(rawData) {
  return Object.fromEntries(
      rawData.split(';')
          .map(param => param.split(':').map(s => s.trim()))
          .filter(([key, value]) => key && value)
          .map(([key, value]) => [key, convertValue(value)])
  );
}

function parseComplexFormat(rawData) {
  const result = {};
  rawData.replace(/(\w+):\{([^}]*)\}/g, (_, key, value) => {
      result[key] = parseSimpleFormat(value);
  });
  rawData.split(';').forEach(part => {
      if (!part.includes('{') && !part.includes('}')) {
          const [key, value] = part.split(':').map(s => s.trim());
          if (key && value && !(key in result) && !(key in (result.query || {}))) {
              result[key] = convertValue(value);
          }
      }
  });
  return result;
}

function convertValue(value) {
  return value === 'true' ? true : value === 'false' ? false : (!isNaN(value) ? Number(value) : value);
}


function extractPageNumber(url) {
  const pageMatch1 = url.match(/page\/(\d+)/);
  const pageMatch2 = url.match(/paged=(\d+)/);
  return pageMatch1 ? pageMatch1[1] : (pageMatch2 ? pageMatch2[1] : 1);
}

function update_url_params(params = {}, paginationKey = 'paged', pageNumber = 1) {
  const url = new URL(window.location.origin + window.location.pathname);

  url.pathname = url.pathname.replace(/\/page\/\d+/, '');

  if (pageNumber > 1) {
    url.searchParams.set(paginationKey, pageNumber);
  } else {
    url.searchParams.delete(paginationKey);
  }

  Object.entries(params).forEach(([key, value]) => {
    if (value && value !== '') {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  });

  update_url(url);
}
function update_url(url){
  window.history.pushState({}, '', url);
}

$(function(){
  // PAGINATION
  $(document).on( "click",'[data-vy-ajax-pager] a.page-numbers', function(e) {
    e.preventDefault();

    const pager = $(this).closest('[data-vy-ajax-pager]');
    const pagerParams = getDataParams(pager,'data-vy-ajax-pager');
    const pageNumber = extractPageNumber($(this).attr('href'));

    pager.find('.page-numbers').removeClass('uk-active current');
    $(this).addClass('uk-active');

    if (pagerParams.scroll) {
      UIkit.scroll($(pagerParams.scroll)).scrollTo($(pagerParams.scroll));
    }

    ajax_filter(pager.closest('[data-vy-ajax]'), pageNumber);
  });
  // FORM
  $('[data-vy-ajax-form]').submit(function(e){
    e.preventDefault();
    ajax_filter($(this).closest('[data-vy-ajax]'));
  });
  // TAXONOMY
  $('select[data-vy-ajax-taxonomy]').change(function(){
    ajax_filter($(this).closest('[data-vy-ajax]'));
  });
  $('select[data-vy-ajax-metaquery]').change(function(){
    ajax_filter($(this).closest('[data-vy-ajax]'));
  });
  // DROPDOWN
  $('[data-vy-ajax-dropdown] a').click(function(e){
    e.preventDefault();

    const dropdown = $(this).closest('[data-vy-ajax-dropdown]');

    dropdown.find('a').removeClass('uk-active');
    $(this).addClass('uk-active');


    var name = $(this).data('name');
    var slug = $(this).data('slug');
    var id = $(this).data('id');
    $('#'+id+' .text').text(name);

    const select = dropdown.siblings('[data-vy-ajax-taxonomy][name="'+id+'"]');
    if(select.length){
      select.find('option[value="'+slug+'"]').prop('selected', true).trigger('change');
    }

    UIkit.dropdown(dropdown).hide(false);

    var newUrl = $(this).attr('href');
    window.history.pushState({ path: newUrl }, '', newUrl);
  });
  // FILTER AJAX
  function ajax_filter(ajax,pageNumber = 1){
    if(ajax.length == 0){
      console.error('Aucun élément [data-vy-ajax] trouvé');
      return;
    }

    const ajaxParams = getDataParams(ajax);
    const urlParams = {};

    var taxonomy = {};
    var metaquery = {};
    var search = false;
    if(ajax.find('[data-vy-ajax-search]').length){
      search = ajax.find('[data-vy-ajax-search]').val();
      var paramsSearch = getDataParams(ajax.find('[data-vy-ajax-search]'),'data-vy-ajax-search');
      if(paramsSearch.addurl) urlParams[paramsSearch.addurl] = search;
    }

    ajax.find('select[data-vy-ajax-taxonomy]').each(function() {
      const paramsTaxo = getDataParams(this,'data-vy-ajax-taxonomy');
      if($(this).val() != '0'){
        taxonomy[paramsTaxo.taxonomy] = $(this).val();
        if (paramsTaxo.addurl) urlParams[paramsTaxo.addurl] = $(this).val();
      }
    });

    ajax.find('select[data-vy-ajax-metaquery]').each(function() {
      const paramsMetaquery = getDataParams(this,'data-vy-ajax-metaquery');
      if($(this).val() != '0'){
        metaquery[paramsMetaquery.metakey] = $(this).val();

        if (paramsMetaquery.addurl) urlParams[paramsMetaquery.addurl] = $(this).val();
      }
    });

    $('.vy_ajax_skeleton').addClass('--active');

    var data = {
      action: 'filter',
      afp_nonce: afp_vars.afp_nonce,
      params: ajaxParams,
      taxonomy: taxonomy,
      metaquery: metaquery,
      paged: pageNumber,
      search: search,
    };


    if(ajaxParams.items_class && ajaxParams.items_class != '' && ajaxParams.items_path && ajaxParams.items_path != ''){
      $.post( afp_vars.afp_ajax_url, data, function(response) {
        if(response == 'error' || response == null){
          console.error('error response ajax');
          return;
        }else if(response) {
          if(!ajaxParams.featured) ajax.find('[data-vy-ajax-featured]').remove();
          $(ajaxParams.items_class).empty();
          $(ajaxParams.items_class).append( response );
          $('.vy_ajax_skeleton').removeClass('--active');

          update_url_params(
            urlParams,
            ajaxParams.url_pagination || 'paged',
            pageNumber
          );
        };
      });
    }else{
      console.error('Need items_path and items_class in data-vy-ajax');
      return;
    }

  }
});

$(window).on('load', function(event) {
  if ($('#field_payment_label').length) {
    $('fieldset[aria-labelledby="field_payment_label"]').find('input[type="tel"], select').each(function() {
      if ($(this).is('input[type="tel"]')) {
          $(this).addClass('uk-input');
      } else if ($(this).is('select')) {
          $(this).addClass('uk-select');
      }
      $(this).closest('.frm_form_field').addClass('uk-margin-bottom');
    });
  }
  if ($('#field_flowers_resume').length) {
    flowerResume()
  }
});


$(document).on('frmPageChanged', function(event, form, response) {

  var formID = $(form).find('input[name="form_id"]').val();
  if ($('#field_payment_label').length) {
    $('fieldset[aria-labelledby="field_payment_label"]').find('input[type="tel"], select').each(function() {
      if ($(this).is('input[type="tel"]')) {
          $(this).addClass('uk-input');
      } else if ($(this).is('select')) {
          $(this).addClass('uk-select');
      }
      $(this).closest('.frm_form_field').addClass('uk-margin-bottom');
    });
  }
  if (response['page'] == formID) {
    flowerResume()

  }else if(response['page'] == '1'){
    if($('#field_flower_id').val() != "undefined" && $('#field_flower_id').val() !== null) {
        var flower_id = $('#field_flower_id').val();
        $('.vy_deces_flower').each(function() {
          if($(this).data('id') == flower_id){
            $(this).addClass('is-selected');
          }
        });
    }
  }

});

function flowerResume(){
  var infos = {};

  const fields = [
    { key: 'post_id', selector: '#flowers-form input#field_post_id' },
    { key: 'flower_id', selector: '#flowers-form input#field_flower_id' },
    { key: 'shipping', selector: '#flowers-form select#field_shipping option[selected="selected"]' }
];

fields.forEach(field => {
  let element = $(field.selector);
  let value;

  if (field.key === 'shipping') {
      value = element.attr('data-frmprice');
  } else {
      value = element.val();
  }
    if (value !== null && value !== undefined && value !== '') {
        infos[field.key] = value;
    }
});


  $('#field_flowers_resume').addClass('vy_ajax_loading');

    var data = {
      action: 'flowers_resume', // function to execute
      afp_nonce: afp_vars.afp_nonce, // wp_nonce
      infos: infos,
    };


    // console.log(data);

    $.get( afp_vars.afp_ajax_url, data, function(response) {
      if( response ) {
        $('#field_flowers_resume').removeClass('vy_ajax_loading');
        // console.log(response);
        $('#field_flowers_resume').empty();
        // $('.vy_archive_deces_nopost').remove();
        $('#field_flowers_resume').append( response );
        // $('body').removeClass('vy_ajax_loading');
        // $('html, body').animate({
        //   scrollTop: $("main").offset().top
        // }, 500);
      };
    });
}
